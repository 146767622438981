import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAlerts } from './hooks/useAlerts';

import { AuthContext } from './context/authContext';
import { AlertContext } from './context/alertContext';

import { useAuth } from './hooks/useAuth';
import { Navigation } from './layout/Navigation';
import { Main } from './layout/Main/Main';
import { Alerts } from './layout/Alerts';

import Auth from './pages/Auth';
import { AppRouter } from './Routes/AppRouter';

import './globalStyles.scss';
import './normalize.scss';

function App() {
    const auth = useAuth();
    const alertLogic = useAlerts();

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <AlertContext.Provider value={alertLogic}>
            <AuthContext.Provider value={auth}>
                <>
                    <Alerts />
                    {auth.isAuth
                        ? <>
                            <Navigation />
                            <Main>
                                <AppRouter />
                            </Main>
                        </>
                        : <Auth />
                    }
                </>
            </AuthContext.Provider>
        </AlertContext.Provider>
    );
}

export default App;