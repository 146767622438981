import { useContext } from 'react';
import { AlertContext } from '../../context/alertContext';

import { ReactComponent as InfoIco } from "./info.svg";

import './styles.scss';

export const Alerts = () => {
    const { alerts } = useContext(AlertContext);

    return (
        <div className="alertsContainer">
            {alerts.map((alert) =>
                <div key={alert.id} className={`alert ${alert.status || 'warn'}`}>
                    <InfoIco />  {alert.message}
                </div>
            )}
        </div>
    );
};