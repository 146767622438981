import { forwardRef } from 'react';

import './styles.scss';

export const Input = forwardRef(function Input(props, ref) {
    const { textArea, className = '', placeholder, type, children, ...other } = props;

    if (type === 'checkbox') {
        return (
            <label ref={ref} className={`styledCheckInput ${className}`}>
                <input type={type} {...other} />
                {children}
            </label>
        );
    }

    if (textArea) {
        return <textarea ref={ref} type={type} placeholder={placeholder} className={`styledInput styledInput--textArea ${className}`} {...other} />;
    }

    return <input ref={ref} type={type} placeholder={placeholder} className={`styledInput ${className}`} {...other} />;

});