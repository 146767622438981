import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AlertContext } from '../../context/alertContext';

import { useApi } from '../../hooks/useApi';
import { useQueryParams } from '../../hooks/useQueryParams';

import { convertUTCDate } from '../../utils/dateUtils';

import Loading from '../../components/Loading';
import { Container } from '../../components/Container';
import { HeaderWithTitle } from '../../components/HeaderWithTitle';
import { Button } from '../../components/Button';

import './styles.scss';

export const CostumerEditor = () => {
    const navigate = useNavigate();
    const [customerId] = useQueryParams('id');
    const { getApi } = useApi();
    const { addAlert } = useContext(AlertContext);
    const [data, setData] = useState();
    const [showRemoveWarn, setShowRemoveWarn] = useState(false);

    useEffect(() => {
        getCustomer();
    }, []);

    const getCustomer = async () => {
        try {
            const response = await getApi(`admin/customers/${customerId}`);

            if (response?.status !== 200) {
                return navigate('/');
            }

            const responseJson = await response?.json();

            setData(responseJson);
        } catch (error) {

        }
    };

    const saveCustomer = async (event) => {
        event.preventDefault();

        const response = await getApi(`admin/customers`, 'PUT', { customerData: data });

        if (response.status !== 200) {
            return;
        }

        addAlert(`Дані клієнта ${data.name} ${data.surname} оновлено`, 'success');

        return navigate(-1);
    };

    const onChangeHandler = (event) => {
        const target = event.target;
        const name = target.name;

        if (target.type === 'checkbox') {
            return setData({
                ...data,
                [name]: target.checked
            });
        }

        setData({
            ...data,
            [name]: target.value
        });
    };

    if (!data) {
        return <Loading />;
    }

    const setShowRemoveWarnHandler = (event) => {
        setShowRemoveWarn(event.currentTarget.value === 'true');
    };

    const removeCustomer = async () => {
        setShowRemoveWarn(false);

        const response = await getApi(`admin/customers/${customerId}`, 'DELETE');

        if (response?.status === 200) {
            return navigate('/');
        }
    };

    return (
        <>
            {showRemoveWarn && <div className="modalMenu">
                <p>
                    Чи дійсно видалити <b>назавжди</b> клієнта {data.name || ''} {data.surname || ''}?
                </p>
                <Button value={'false'} onClick={setShowRemoveWarnHandler}>Ні</Button>
                <Button value={'false'} onClick={removeCustomer} className="removeButton">Так</Button>
            </div>}
            <HeaderWithTitle className="test" customer={data} />

            <Container className={showRemoveWarn ? 'blur' : ''}>
                <p>Карту клієнта створено: {convertUTCDate(data.id)}</p>
                <form onSubmit={saveCustomer} className='editor'>
                    <div className='editor__block'>
                        <label className='inputLabel'>
                            Імя:
                            <input required name='name' value={data.name || ''} onChange={onChangeHandler} />
                        </label>
                        <label className='inputLabel'>
                            Прізвище:
                            <input required name='surname' value={data.surname || ''} onChange={onChangeHandler} />
                        </label>
                        <label className='inputLabel'>
                            Років:
                            <input name='age' value={data.age || ''} onChange={onChangeHandler} />
                        </label>
                        <label className='inputLabel'>
                            Як клієнт взнав про нас:
                            <textarea name='howFind' value={data.howFind || ''} onChange={onChangeHandler} />
                        </label>
                        <label className='inputLabel'>
                            Номер телефону:
                            <input name='phone' value={data.phone || ''} onChange={onChangeHandler} />
                        </label>
                        <label className='inputLabel'>
                            Посилання на соц. мережу:
                            <input name='socialLink' value={data.socialLink || ''} onChange={onChangeHandler} />
                        </label>
                        <label className='inputLabel'>
                            Нотатка:
                            <textarea name='note' value={data.note || ''} onChange={onChangeHandler} />
                        </label>
                    </div>

                    <div className='editor__block'>
                        <h2>Фактори клієнта:</h2>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='firstTime' checked={data.firstTime || false} onChange={onChangeHandler} />
                            Клієнт робить депіляцію вперше
                        </label>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='complicationsBefore' checked={data.complicationsBefore || false} onChange={onChangeHandler} />
                            Клієнт робив депіляцію раніше та виникали ускладнення
                        </label>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='allergic' checked={data.allergic || false} onChange={onChangeHandler} />
                            У клієнта схильність до алергічних реакцій
                        </label>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='epilepsy' checked={data.epilepsy || false} onChange={onChangeHandler} />
                            У клієнта епілепсія
                        </label>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='diabete' checked={data.diabete || false} onChange={onChangeHandler} />
                            У клієнта цукровий діабет
                        </label>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='pregnant' checked={data.pregnant || false} onChange={onChangeHandler} />
                            Клієнт вагітний
                        </label>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='acne' checked={data.acne || false} onChange={onChangeHandler} />
                            Клієнт приймає препарати на акне
                        </label>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='rosacea' checked={data.rosacea || false} onChange={onChangeHandler} />
                            У клієнта Розацеа
                        </label>
                    </div>

                    <div className='editor__block'>
                        <h2>Побажання і дозволи клієнта:</h2>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='subscribeMarketing' checked={data.subscribeMarketing || false} onChange={onChangeHandler} />
                            Клієнт хоче отримувати інформацію про акції
                        </label>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='subscribeAboutRecordIn24h' checked={data.subscribeAboutRecordIn24h || false} onChange={onChangeHandler} />
                            Клієнт хоче отримувати попередження про запис за добу
                        </label>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='acceptPhotoPermission' checked={data.acceptPhotoPermission || false} onChange={onChangeHandler} />
                            Клієнт погодився на фото до/після та публікацію
                        </label>
                    </div>

                    <div className='editor__block'>
                        <h2>Зручний спосіб зв'язку клієнта:</h2>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='acceptPhoneContact' checked={data.acceptPhoneContact || false} onChange={onChangeHandler} />
                            Дзвінок по телефону
                        </label>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='acceptSmsContact' checked={data.acceptSmsContact || false} onChange={onChangeHandler} />
                            Смс
                        </label>
                        <label className='checkboxLabel'>
                            <input type='checkbox' name='acceptSocialContact' checked={data.acceptSocialContact || false} onChange={onChangeHandler} />
                            Telegram/Instagram/Facebook
                        </label>
                        <label className='inputLabel inputLabel--special'>
                            Інший спосіб контакту:
                            <input name='anotherWayContact' value={data.anotherWayContact || ''} onChange={onChangeHandler} />
                        </label>
                    </div>
                    <div className='editor__buttons'>
                        <Button type="button" value={'true'} onClick={setShowRemoveWarnHandler} className="removeButton">Видалити</Button>
                        <Button type="submit">Зберегти</Button>
                    </div>
                </form>
            </Container>
        </>
    );
};;