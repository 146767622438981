import { Link } from 'react-router-dom';
import './styles.scss';

export const Button = ({ children, to, href, className, ...props }) => {
    const thisClassName = `button ${className || ''}`;

    if (to) {
        return (
            <Link className={thisClassName} to={to} {...props}>
                {children}
            </Link>
        );
    }

    if (href) {
        return <a href={href} className={thisClassName} {...props}>
            {children}
        </a>;
    }

    return (
        <button className={thisClassName} {...props}>
            {children}
        </button>
    );
};