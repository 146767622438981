import { Route, Routes } from 'react-router-dom';
import { routes } from './routes.config';

export const AppRouter = () => {

    return (
        <Routes>
            {routes?.map((routeConfig) => <Route key={routeConfig.path} {...routeConfig} />)}
        </Routes>
    );
};
