import { Page } from './Page';
import { ROUTE_NAMES } from './routes.constants';

import { Customers } from '../pages/Customers';
import { CustomerInfo } from '../pages/CustomerInfo';
import { Finances } from '../pages/Finances';
import { CreateCustomer } from '../pages/CreateCustomer';
import { CostumerEditor } from '../pages/CostumerEditor';
import { Settings } from '../pages/Settings';

export const routes = [
    {
        path: ROUTE_NAMES.CUSTOMER,
        element:
            <Page>
                <CustomerInfo />
            </Page>,
    },
    {
        path: ROUTE_NAMES.CUSTOMER_CREATE,
        element:
            <Page>
                <CreateCustomer />
            </Page>,
    },
    {
        path: ROUTE_NAMES.CUSTOMER_EDITOR,
        element:
            <Page>
                <CostumerEditor />
            </Page>,
    },
    {
        path: ROUTE_NAMES.FINANCES,
        element:
            <Page>
                <Finances />
            </Page>,
    },
    {
        path: ROUTE_NAMES.SETTINGS,
        element:
            <Page>
                <Settings />
            </Page>,
    },
    {
        path: '*',
        element:
            < Page >
                <Customers />
            </Page >,
    },
];
