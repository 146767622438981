import { useContext } from 'react';

import { AuthContext } from '../context/authContext';
import { AlertContext } from '../context/alertContext';

import { getResMessage } from '../utils/getResMessage';
import { url, isLocalServer } from '../credentials';

export const useApi = () => {
    const { setIsAuth } = useContext(AuthContext);
    const { addAlert } = useContext(AlertContext);

    const getApi = async (path, method, data) => {
        try {
            const response = await fetch(`${url}/${path}`, {
                headers: {
                    'credentials': true,
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'gzip, deflate, br',
                    'Accept': '*/*',
                    'Connection': 'keep-alive',
                    'Access-Control': 'Allow-Origin'
                },
                origin: url,
                credentials: isLocalServer ? 'same-origin' : 'include',
                method,
                body: JSON.stringify(data)
            });

            if (response.status === 401) {
                setIsAuth(false);
                localStorage.removeItem('isAuth');
                addAlert('Потрібно авторизуватись');
            }

            if (response.status === 400 || response.status === 404) {
                addAlert(await getResMessage(response));
            }

            return response;
        } catch (error) {
            addAlert('Появились проблеми з сервером, подзвони Назару', 'error');

            return {
                status: 500
            };
        }
    };

    return { getApi };
};