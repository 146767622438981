import { useSearchParams } from 'react-router-dom';

export const useQueryParams = (key, initialState) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const selectedParams = searchParams.get(key) || `${initialState}`;

    const setSelectedParams = async (value) => {
        return new Promise((resolve) => {
            setSearchParams(`${key}=${value}`);

            if (selectedParams === `${value}`) {
                resolve(true);
            }
        });
    };

    return [selectedParams, setSelectedParams,];
};
