import { Input } from '../Input';
import { ReactComponent as SearchIco } from "./search.svg";
import "./styles.scss";

export const Search = ({ ...props }) => {
    return (
        <div className="search">
            <SearchIco className="ico" />
            <Input type="text" placeholder="Пошук" {...props} />
        </div>
    );
};