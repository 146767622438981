export const convertUTCDate = (utc) => {
    if (!utc) {
        return '-';
    }

    return new Date(utc).toLocaleDateString('pl');
};

export const getUTCdate = (customDate) => {
    const result = new Date(customDate || Date.now()).getTime();

    if (isNaN(result)) {
        console.error('getUTCdate broke');
        return 0;
    }

    return result;
};

export const getCurrentInputDate = (customDate) => {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    const localISOTime = (new Date((customDate || Date.now()) - tzoffset)).toISOString();

    return localISOTime.split('T')[0];
};