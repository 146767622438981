const statuses = {
    e0: 'Появились проблеми з сервером, подзвони Назару',
    e1: 'Щось пішло не так',
    e2: 'Не знайдено ід або дати',
    e3: 'ID Не знайдено',
    e4: 'Неповноціні дані',
    e5: 'Базу даних не знайдено',
    e6: 'Платежів не знайдено (Критична проблема з базами даних)',

    ec0: 'Користувача не знайдено',
    ec1: 'Сталась помилка під час зміни даних клієнта',
    ec2: 'Помилка під час створення карти клієнта',
    ec3: 'Дані клієнта не знайдено',
    ec4: 'Сталась помилка під час видалення клієнта',

    ed0: 'Дата не може бути майбутньою',

    ee0: 'Сталась помилка під час додавання витрат',
    ee1: 'Не получилось видалити витрату',
    ee2: 'Відсутня дата витрати',

    ev0: 'Помилка під час створення візити',
    ev1: 'Візит цього дня вже існує',
    ev2: 'Візити цього дня не знайдено',

    ei0: 'Помилка під час додавання доходу',
    ei1: 'Помилка під час зміни доходу',

    ea0: 'Пароль не вірний',


    sv0: 'Безплатний візит успішно додано',
    sv1: 'Візит і платіж успішно додано',

    sc0: 'Дані клієнта успішно зміненно',
    sc1: 'Клієнта успішно створенно',
    sc2: 'Клієнта успішно видалено',

    sa0: 'Вхід виконано успішно',

    se0: 'Витрату успішно додано',
    se1: 'Витрату успішно видалено',

    sp0: 'Платіж успішно видалено',
};

export const getResMessage = async (response) => {
    if (!response) {
        return statuses.e1;
    }

    try {
        const jsonResponse = await response.json();

        const code = jsonResponse.code;

        if (code) {
            return statuses[code];
        }

        return null;
    } catch (error) {
        return statuses.e1;
    }
};