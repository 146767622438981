import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AlertContext } from '../../context/alertContext';
import { useApi } from '../../hooks/useApi';
import { useQueryParams } from '../../hooks/useQueryParams';

import { ROUTE_NAMES } from '../../Routes/routes.constants';

import Loading from '../../components/Loading';
import { Container } from '../../components/Container';
import { HeaderWithTitle } from '../../components/HeaderWithTitle';
import { ReactComponent as EditIco } from "../../components/edit.svg";
import { ReactComponent as ProfileIco } from "../../components/profile.svg";
import { ReactComponent as FacebookIco } from "../../components/facebook.svg";
import { ReactComponent as InstagramIco } from "../../components/instagram.svg";
import { ReactComponent as AnotherLink } from "../../components/link.svg";
import { ReactComponent as TrashIco } from "../../components/trash.svg";

import { Button } from '../../components/Button';
import { Input } from '../../components/Input';

import { convertUTCDate, getCurrentInputDate } from '../../utils/dateUtils';
import { sortArrayByValue } from '../../utils/sort';

import "./styles.scss";

export const CustomerInfo = () => {
    const [data, setData] = useState(null);
    const [customerId] = useQueryParams('id');
    const [editableVisitId, setEditableVisitId] = useState(null);

    const dateRef = useRef();

    const { addAlert } = useContext(AlertContext);

    const initialVisitValue = {
        id: data?.id || customerId,
        name: '',
        date: new Date(new Date().toDateString()).getTime(),
        price: '',
        registeredPay: false,
    };

    const [visitValue, setVisitValue] = useState(initialVisitValue);

    const { getApi } = useApi();

    const navigate = useNavigate();

    useEffect(() => {
        getCustomer();
    }, []);

    useEffect(() => {
        const thisVisit = data?.visits.filter((visit) => visit.date === editableVisitId)[0];

        if (thisVisit) {
            setVisitValue({
                id: data.id,
                name: thisVisit.name,
                date: thisVisit.date,
                newDate: thisVisit.date,
                price: thisVisit.price,
                registeredPay: thisVisit.registeredPay,
            });
        }
    }, [editableVisitId]);

    const getCustomer = async () => {
        try {
            const response = await getApi(`admin/customers/${customerId}`);

            if (response.status !== 200) {
                return navigate('/');
            }

            const responseJson = await response?.json();

            setEditableVisitId(null);
            setVisitValue(initialVisitValue);
            setData(responseJson);
        } catch (error) {
            return navigate('/');
        }
    };

    const addVisitHandler = async (event) => {
        event.preventDefault();

        const response = await getApi('admin/customers/visit', 'POST', visitValue);

        if (response.status !== 200) {
            return;
        }

        setVisitValue({
            ...visitValue,
            price: '',
            name: '',
            registeredPay: false
        });

        addAlert(`Візит ${visitValue.name} добавлений успішно`, 'success');

        getCustomer();
    };

    const modifyVisitHandler = async (event) => {
        event.preventDefault();

        const response = await getApi('admin/customers/visit', 'PUT', visitValue);

        if (response.status !== 200) {
            return;
        }

        addAlert(`Візит ${visitValue.name} змінено успішно`, 'success');

        setVisitValue({
            ...visitValue,
            price: '',
            name: '',
            registeredPay: false
        });

        getCustomer();
        setEditableVisitId(null);
    };

    const removeCustomerVisit = async (event) => {
        const date = event.currentTarget.value;

        const response = await getApi(`admin/customers/visit/${customerId}?date=${date}`, 'DELETE');

        if (response.status !== 200) {
            return;
        }

        addAlert(`Візит ${visitValue.name} успішно видалено`, 'warn');

        getCustomer();
    };

    const onChangeHandler = (event) => {
        const target = event.target;
        const name = target.name;

        const setValue = (value) => {
            return setVisitValue({
                ...visitValue,
                [name]: value
            });
        };

        if (target.type === 'checkbox') {
            return setValue(target.checked);
        }

        if (name === 'date' || name === 'newDate') {
            return setValue(new Date(target.value).getTime());
        }

        setValue(target.value);
    };

    const goToEdit = () => {
        navigate(`${ROUTE_NAMES.CUSTOMER_EDITOR}?id=${customerId}`);
    };

    const getSocialIco = (link) => {
        const include = (socialName) => {
            return link.includes(socialName);
        };

        if (include('instagram')) {
            return <InstagramIco />;
        }

        if (include('facebook')) {
            return <FacebookIco />;
        }

        return <AnotherLink />;
    };

    const setEditableVisit = (event) => {
        const id = +event.currentTarget.value;

        if (editableVisitId === id) {
            setVisitValue(initialVisitValue);
            dateRef.current.value = getCurrentInputDate();
            return setEditableVisitId(null);
        }

        dateRef.current.value = getCurrentInputDate(id);

        setEditableVisitId(id);
    };

    if (!data) {
        return <Loading />;
    }

    return (
        <>
            <HeaderWithTitle customer={data} >
                <button className="editButton" onClick={goToEdit}><EditIco /> Редагувати</button>
            </HeaderWithTitle>

            <Container>
                {data.note && <p className="adminNote"><b>Нотатка:</b> {data.note}</p>}
                <div className="information">
                    <div className="card">
                        <ProfileIco className="card__avatar" />
                        <h2 className="card__title">Інформація</h2>
                        <p>Створено: <span>{convertUTCDate(data.id)}</span></p>
                        <p>Змінено: <span>-</span></p>
                        <p className="marginTop">Років: <span>{data.age}</span></p>
                        <p>Телефон: <span>{data.phone}</span></p>
                        <p className="marginTop card__lastText">
                            Як клієнт взнав про нас:<br />
                            <span>{data.howFind}</span>
                        </p>
                        {data.socialLink &&
                            <a target="_blank" className="socialButton" href={data.socialLink} rel="noreferrer">
                                {getSocialIco(data.socialLink)}
                            </a>
                        }
                    </div>
                    <div className="card">
                        <h2 className="card__title">Фактори клієнта:</h2>
                        <ul>
                            {data.firstTime && <li>Клієнт робить депіляцію вперше</li>}
                            {data.complicationsBefore && <li>Клієнт робив депіляцію раніше та виникали ускладнення</li>}
                            {data.allergic && <li>У клієнта схильність до алергічних реакцій</li>}
                            {data.epilepsy && <li>У клієнта епілепсія</li>}
                            {data.diabete && <li>У клієнта цукровий діабет</li>}
                            {data.pregnant && <li>Клієнт вагітний</li>}
                            {data.acne && <li>Клієнт приймає препарати на акне</li>}
                            {data.rosacea && <li>У клієнта Розацеа</li>}
                        </ul>
                    </div>
                    <div className="card">
                        <h2 className="card__title">Побажання і дозволи клієнта:</h2>
                        <ul>
                            {data.subscribeMarketing && <li>Клієнт хоче отримувати інформацію про акції</li>}
                            {data.subscribeAboutRecordIn24h && <li>Клієнт хоче отримувати попередження про запис за добу</li>}
                            {data.acceptPhotoPermission && <li>Клієнт погодився на фото до/після та публікацію</li>}
                        </ul>
                    </div>
                    <div className="card">
                        <h2 className="card__title">Зручний спосіб зв"язку клієнта:</h2>
                        <ul>
                            {data.acceptPhoneContact && <li>Дзвінок по телефону</li>}
                            {data.acceptSmsContact && <li>Смс</li>}
                            {data.acceptSocialContact && <li>Telegram/Instagram/Facebook</li>}
                            {data.anotherWayContact && < li > Інше: {data.anotherWayContact}</li>}
                        </ul>
                    </div>
                </div >
                <div className="visits">
                    <div className="visits__header">
                        <h2 className="header__title">Візити ({data.visits?.length})</h2>
                        <form className={editableVisitId ? 'editableForm' : ''} onSubmit={editableVisitId ? modifyVisitHandler : addVisitHandler}>
                            <Input
                                value={visitValue.name}
                                className="form__input form__input--long"
                                name="name"
                                onChange={onChangeHandler}
                                placeholder="Нотатка"
                            />
                            <Input
                                defaultValue={getCurrentInputDate()}
                                className="form__input form__input--short"
                                name={editableVisitId ? 'newDate' : 'date'}
                                onChange={onChangeHandler} type="date"
                                placeholder="Дата"
                                ref={dateRef}
                            />
                            <Input
                                value={visitValue.price}
                                className="form__input form__input--short"
                                type="number"
                                step="0.01"
                                min="0"
                                name="price"
                                onChange={onChangeHandler}
                                placeholder="Ціна"
                            />
                            <Input checked={visitValue.registeredPay}
                                name="registeredPay"
                                onChange={onChangeHandler} type="checkbox" >
                                Зареєстрована платність?
                            </Input>
                            {editableVisitId
                                ? <Button className="greenBtn">
                                    Змінити
                                </Button>
                                : <Button>
                                    Створити
                                </Button>}
                        </form>
                    </div>
                    <table className='visits__table'>
                        <thead>
                            <tr>
                                <th>
                                    Назва
                                </th>
                                <th className='hideMobile'>
                                    Дата
                                </th>
                                <th>
                                    Ціна
                                </th>
                                <th>
                                    Зареєстровано?
                                </th>
                                <th>
                                    Дія
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortArrayByValue(data.visits, 'date').map((visit) =>
                                    <tr key={visit.date}>
                                        <td className='name'>
                                            {visit.name || '-'}
                                        </td>
                                        <td className='date'>
                                            {convertUTCDate(visit.date)}
                                        </td>
                                        <td>
                                            {visit.price || '-'} zł
                                        </td>
                                        <td>
                                            {visit.registeredPay ? 'Так' : '-'}
                                        </td>
                                        <td>
                                            {
                                                visit.date === editableVisitId && <button
                                                    className='editableButton editableButton--remover'
                                                    value={visit.date}
                                                    onClick={removeCustomerVisit}
                                                >
                                                    <TrashIco />
                                                </button>
                                            }
                                            <button
                                                className={`editableButton ${visit.date === editableVisitId ? 'green' : ''}`}
                                                value={visit.date}
                                                onClick={setEditableVisit}>
                                                <EditIco />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </Container>
        </>
    );
};