import { useState } from 'react';

export const useAlerts = () => {
    const [alerts, setAlerts] = useState([]);

    const cleanAlert = () => {
        setTimeout(() => {
            setAlerts((state) => state.slice(0, state.length - 1));
        }, 4000);
    };

    const addAlert = (message, status = '') => {
        if (!message) {
            return;
        }

        setAlerts([
            {
                message,
                id: new Date().getTime().toString(36),
                status
            },

            ...alerts,
        ]);

        cleanAlert();
    };

    return {
        alerts,
        addAlert
    };
};