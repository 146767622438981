import { useEffect, useState } from 'react';

import { useApi } from '../../hooks/useApi';

import Loading from '../../components/Loading';
import { useQueryParams } from '../../hooks/useQueryParams';
import { HeaderWithTitle } from '../../components/HeaderWithTitle';
import { Container } from '../../components/Container';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';

import { convertUTCDate, getCurrentInputDate, getUTCdate } from '../../utils/dateUtils';
import { sortArrayByValue } from '../../utils/sort';

import { ReactComponent as TrashIco } from "../../components/trash.svg";

import './styles.scss';

export const Finances = () => {
    const expensesInitialFormData = {
        name: '',
        price: '',
        date: new Date(getCurrentInputDate()).getTime(),
    };

    const [paymentData, setPaymentData] = useState(null);
    const [monthsData, setMonthsData] = useState(null);
    const [month, setMonth] = useQueryParams('date', '');
    const [viewBlock, setViewBlock] = useState(0);
    const [dateView, setDateView] = useState(0);
    const [expensesFormData, setExpensesFormData] = useState(expensesInitialFormData);

    const { getApi } = useApi();

    useEffect(() => {
        getAllMonth();
    }, []);

    useEffect(() => {
        getPayments(month);
    }, [month]);

    const getPayments = async (date = '') => {
        const response = await getApi(`payments/getPayments${date ? `?date=${date}` : ''}`, 'GET');

        if (response.status !== 200) {
            setPaymentData({
                income: [],
                expenses: []
            });

            return;
        }

        const jsonResponse = await response.json();

        setPaymentData({
            income: sortArrayByValue(jsonResponse.income, 'date'),
            expenses: sortArrayByValue(jsonResponse.expenses, 'date'),
        });
    };

    const getAllMonth = async () => {
        const response = await getApi('payments/getAllMonthPayments', 'GET');

        if (response.status !== 200) {
            return;
        }

        const jsonResponse = await response.json();
        setMonthsData(jsonResponse.reverse());
    };

    const addExpenses = async (event) => {
        event.preventDefault();

        const response = await getApi('payments', 'POST', {
            date: expensesFormData.date,
            name: expensesFormData.name,
            price: expensesFormData.price,
        });

        if (response.status !== 200) {
            return;
        }

        const selectedDate = new Date(expensesFormData.date);

        setExpensesFormData({
            ...expensesFormData,
            name: '',
            price: ''
        });
        setMonth(`${selectedDate.getFullYear()}.${selectedDate.getMonth() + 1}`);
        getPayments(month);
        getAllMonth();
    };

    const removeExpenses = async (event) => {
        const value = event.currentTarget.value;

        const response = await getApi(`payments?date=${value}`, 'DELETE');

        if (response.status !== 200) {
            return;
        }

        getPayments(month);
    };

    const changeExpenses = (event) => {
        const target = event.target;

        if (target.name === 'date') {
            const newData = {
                ...expensesFormData,
                [target.name]: new Date(target.value).getTime()
            };

            return setExpensesFormData(newData);
        }

        const newData = {
            ...expensesFormData,
            [target.name]: target.value
        };

        setExpensesFormData(newData);
    };

    const selectMonth = (event) => {
        setMonth(event.currentTarget.value);
    };

    if (!paymentData || !monthsData) {
        return <Loading />;
    }

    const UTCtime24hAgo = getUTCdate() - (1000 * 60 * 60 * 24);

    const getFilteredDate = (array = []) => {
        if (dateView === 1) {

            return array.filter((object) => object.date > UTCtime24hAgo);
        }

        return array;
    };

    const getGeneralPriceFromArray = (array, isRegistered) => {
        let value = 0;

        getFilteredDate(array).forEach((object) => {
            if (isRegistered) {
                if (object.registeredPay) {
                    value = +value + +object.price;
                }

                return;
            }

            value = +value + +object.price;
        });

        return value.toFixed(2);
    };

    const changeBlockView = (event) => {
        setViewBlock(+event.target.value);
    };

    const changeDateView = (event) => {
        setDateView(+event.target.value);
    };

    const allPforit = getGeneralPriceFromArray(paymentData.income),
        registered = getGeneralPriceFromArray(paymentData.income, true),
        expenses = getGeneralPriceFromArray(paymentData.expenses),
        netProfit = (allPforit - expenses).toFixed(2);

    monthsData.sort((a, b) => {
        const [yearA, monthA] = a.split('.').map(Number);
        const [yearB, monthB] = b.split('.').map(Number);

        return yearA - yearB || monthB - monthA;
    });

    return (
        <>
            <HeaderWithTitle title="Фінанси" >
                <select className="dateSelector" value={month} onChange={selectMonth}>
                    {monthsData.map((month) => (
                        <option key={month} value={month} >
                            {month}
                        </option>
                    ))}
                </select>
            </HeaderWithTitle>
            <Container className="finance">
                <div className='finance__dateViewChanger'>
                    <button
                        className={dateView === 0 ? 'active' : ''}
                        onClick={changeDateView}
                        value={0}
                    >
                        Місяць
                    </button>
                    <button
                        className={dateView === 1 ? 'active' : ''}
                        onClick={changeDateView}
                        value={1}
                    >
                        24h
                    </button>
                </div>
                <div>
                    <h2 className='generalPrice'>Чистий прибуток: <span>{netProfit} zł</span></h2>
                    <h3 className='generalPrice'>Зареєстрований прибуток: <b>{registered} zł</b></h3>
                </div>
                <div className='finance__navigation'>
                    <button
                        value={0}
                        className={viewBlock === 0 ? 'active' : ''}
                        onClick={changeBlockView}
                    >
                        Дохід
                    </button>
                    <button
                        value={1}
                        className={viewBlock === 1 ? 'active' : ''}
                        onClick={changeBlockView}
                    >Витрати
                    </button>
                </div>

                <section className="finance__section">
                    <div className={`section__block section__block--first${viewBlock === 0 ? ' showSectionBlock' : ''}`}>
                        <table className='table1'>
                            <caption className='generalPrice'>
                                Дохід <span>{allPforit} zł</span>
                            </caption>
                            <tbody>
                                <tr>
                                    <th>Клієнт</th>
                                    <th>Дата</th>
                                    <th>Ціна</th>
                                    <th>Зареєстровано?</th>
                                </tr>
                                {getFilteredDate(paymentData.income)[0]
                                    ? getFilteredDate(paymentData.income).map((payment) => {
                                        return (
                                            <tr key={`${payment.date} ${payment.id}`}>
                                                <td className='name'>{payment.name}</td>
                                                <td>{convertUTCDate(payment.date)}</td>
                                                <td>{payment.price} zł</td>
                                                <td>{payment.registeredPay ? 'Так' : '-'}</td>
                                            </tr>
                                        );
                                    })
                                    : <tr>
                                        <td>Пусто</td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                    <div className={`section__block section__block--second${viewBlock === 1 ? ' showSectionBlock' : ''}`}>
                        <h2 className='generalPrice'>Витрати: <b>{expenses} zł</b></h2>
                        <form onSubmit={addExpenses}>
                            <Input
                                onChange={changeExpenses}
                                value={expensesFormData.name}
                                name="name"
                                placeholder="Назва"
                                className='name'
                            />
                            <Input
                                required
                                onChange={changeExpenses}
                                value={expensesFormData.price}
                                name="price"
                                type="number"
                                placeholder="Ціна"
                            />
                            <Input
                                onChange={changeExpenses}
                                name="date"
                                type="date"
                                defaultValue={getCurrentInputDate()}
                            />
                            <Button type="submit">
                                Додати
                            </Button>
                        </form>

                        <table className='table2'>
                            <tbody>
                                <tr>
                                    <th>Назва</th>
                                    <th>Дата</th>
                                    <th>Ціна</th>
                                </tr>
                                {getFilteredDate(paymentData.expenses)[0]
                                    ? getFilteredDate(paymentData.expenses).map((payment) =>
                                        <tr key={payment.date}>
                                            <td className='name'>{payment.name || '-'}</td>
                                            <td>{convertUTCDate(payment.date)}</td>
                                            <td>{payment.price} zł</td>
                                            <td>
                                                <button
                                                    className='remove'
                                                    value={payment.date}
                                                    onClick={removeExpenses}>
                                                    <TrashIco />
                                                </button>
                                            </td>
                                        </tr>)
                                    : <tr>
                                        <td>Пусто</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </section>
            </Container>
        </>
    );
};